import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "self-center mr-4 text-lg font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["flex border border-2 border-current rounded-md", _ctx.color]),
    href: _ctx.link,
    target: "_blank",
    rel: "noopener"
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "m-4 my-4 stroke-current stroke-1" })),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ], 10, _hoisted_1))
}