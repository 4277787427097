
import { WhatsappIcon, FacebookIcon, InstagramIcon } from 'vue3-simple-icons';
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    WhatsappIcon,
    FacebookIcon,
    InstagramIcon,
  },
  props: {
    name: String,
    link: String,
    icon: String,
    colorName: String,
  },
  computed: {
    color(){
      return `text-${this.colorName}`;
    }
  }
})
